html,
body,
#root {
    margin: 0;
    padding: 0;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    background-color: #f7f7f7;
    scroll-behavior: smooth;
}

* {
    -ms-overflow-style: none;
}
::-webkit-scrollbar {
    display: none;
}

@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: auto;
    }
    .proposal-section {
      page-break-inside: avoid;
    }
    .recipe-line {
      page-break-inside: avoid;
    }
    .proposal-totals {
      page-break-inside: avoid;
    }
    .other-costs-section {
      page-break-inside: avoid;
    }
    .about-me-and-links {
      page-break-inside: avoid;
    }
  }
  
  @page {
    size: auto;
    margin: .5in;
  }
